@import '../theme-bootstrap';

.quickshop-wrapper {
  clear: both;
  overflow: hidden;
  position: relative;
  max-width: $max-width;
  margin: 0 auto;
  &.active {
    &::before {
      position: absolute;
      z-index: 2;
      color: $color-gray;
      letter-spacing: 0;
      top: 0;
      #{$ldirection}: 75%;
      margin-#{$ldirection}: -13px;
      content: '';
      width: 18px;
      height: 18px;
      background: url('#{$base-theme-path}img/icons/src/quickshop_arrow.svg') no-repeat $color-white;
      background-size: cover;
      display: block;
    }
    @include breakpoint($medium-up) {
      &::before {
        #{$ldirection}: 0;
        margin-#{$ldirection}: 60px;
      }
      &--2::before {
        #{$ldirection}: 33.33%;
      }
      &--3::before {
        #{$ldirection}: 66.67%;
      }
    }
  }
}

.quickshop {
  &__container {
    @include clearfix;
    border: 1px solid $color-gray;
    position: relative;
    margin: 13px 0 35px;
  }
  &__close {
    color: $color-gray;
    position: absolute;
    width: 18px;
    height: 18px;
    font-size: 18px;
    top: 7px;
    #{$rdirection}: 30px;
    cursor: pointer;
    line-height: 1;
    &::before {
      content: '';
      width: 18px;
      height: 18px;
      background: url('#{$base-theme-path}img/icons/src/close.svg') no-repeat;
      background-size: cover;
      display: block;
    }
    @include breakpoint($medium-up) {
      top: 13px;
      #{$rdirection}: 14px;
    }
    &__text {
      @include hide-text;
      display: block;
    }
  }
  .product-gallery {
    @include swap_direction(padding, 34px 22px 22px 12px);
    border-#{$rdirection}: solid 1px $color-gray;
    &__main {
      height: 100%;
    }
  }

  // Override default product--full (SPP) styles:
  .product {
    &--full {
      .product {
        &__title {
          @include h8;
          @include swap_direction(padding, 0 15px 10px 0);
          &__link {
            color: $color-black;
            text-decoration: none;
          }
        }
        &__rating {
          @include h8;
          text-transform: inherit;
          font-family: $main-font;
          padding: 0 0 10px;
          font-size: 12px;
          line-height: 1.2;
          letter-spacing: 0.2em;
          @include breakpoint($medium-up) {
            padding: 0 0 12px;
          }
        }
      }
    }
    &__social {
      @include swap_direction(padding, 13px 0 0 20px);
    }
    &__divider {
      border-#{$ldirection}: solid 1px $color-gray;
      height: 100%;
      position: absolute;
      #{$ldirection}: 50%;
    }
    &__details {
      padding: 22px;
    }
    &__sku-select-label {
      font-size: 12px;
      line-height: 1.2;
      letter-spacing: 0.2em;
      padding: 10px 0;
      border-top: solid 1px $color-black;
    }
    &__qty {
      @include breakpoint($medium-up) {
        width: 160px;
        .selectbox {
          &.product__options {
            min-width: 100%;
          }
        }
      }
    }
  }
  &--mobile {
    .quickshop {
      &__container {
        background: none;
        padding: 30px 20px;
        border-#{$ldirection}: none;
        border-#{$rdirection}: none;
      }
      &__header {
        @include clearfix;
        position: relative;
        padding-bottom: 14px;
      }
      &__smoosh-wrapper {
        float: $ldirection;
        width: 50%;
        padding-#{$rdirection}: 10px;
      }
      &__select-wrapper {
        float: $rdirection;
        width: 50%;
        padding-#{$ldirection}: 10px;
      }
      &__addtobag {
        margin: 0;
        padding: 30px 0;
      }
    }
    .shade-picker {
      border-top: none;
    }
    .product__button {
      width: 100%;
      text-align: center;
      padding-#{$ldirection}: 0;
      padding-#{$rdirection}: 0;
    }
  }
}

.colorbox__quickshop {
  &#cboxOverlay {
    opacity: 0.68;
    background-color: $color-black;
    #cboxClose {
      color: $color-black;
      font-size: 12px;
      height: 12px;
      #{$rdirection}: 20px;
      top: 15px;
      width: 12px;
    }
  }
  #cboxContent {
    padding: 15px;
    .quickshop {
      &__container {
        border: none;
        margin: 0;
      }
      .wrap {
        padding: 0;
      }
      &__close {
        display: none;
      }
      .product {
        &__header {
          margin-bottom: 1px;
        }
        &__details {
          padding: 23px 0 0;
          width: 55.2%;
        }
        &__social,
        &__divider {
          display: none;
        }
        &__title {
          margin: 0;
          padding: 0;
          border: medium none;
          &-link {
            font-size: 18px;
            letter-spacing: 0;
          }
        }
        &__weight,
        &__unit-price {
          font-weight: 400;
        }
        &__weight-container {
          margin-bottom: 7px;
        }
        &-gallery {
          border: medium none;
          width: 44.8%;
          &__shade,
          &__thumbs {
            display: none;
          }
        }
        &__actions {
          padding: 27px 0 26px;
        }
        &__button--add-to-bag {
          font-size: 16px;
        }
        &__full-details-link {
          border-bottom: 1px solid $color-gray;
          letter-spacing: 0;
          color: $color-black;
          padding: 0;
          &:hover {
            background: none;
            border-color: $color-gray;
            color: $color-gray;
          }
          &-wrapper {
            margin-top: 1px;
          }
        }
      }
      .how-to-use__wrap {
        .outer-wrap {
          display: none;
          &.active {
            display: block;
            .how-to-use {
              &__title {
                padding: 10px 0 11px;
              }
              &__content {
                @include swap_direction(margin, 0 15px 10px 0);
                border-bottom: none;
                padding: 0;
                height: 40px;
                overflow: hidden;
              }
            }
            .view-details-button {
              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
